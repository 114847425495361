<template>
    <div
        class="row spacer-navigation"
        :class="{ 'spacer-footer': page?.body.length == 0 }"
    >
        <div class="col-12">
            <h1 class="scale-6 spacer-40" :id="anchorTitle">
                {{ page.title }}
            </h1>
            <h2
                class="scale-1 scale-1--bold-uppercase spacer-25-20"
                :id="slugifyString(t('magazine.overview.search'))"
            >
                {{ t('magazine.overview.search') }}
            </h2>
            <SearchFakeInput
                :q="displayQ()"
                class="spacer-80-60 search-input"
            />
        </div>
    </div>
    <div v-if="page?.body.length > 0" class="spacer-footer">
        <CmsBody :page="page" />
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useHrefLang } from '~/composables/seo/hreflang';
import { useSearchStore } from '@/stores/search';
import { useAnchorTitle } from '~/composables/useAnchorTitle';
import { slugifyString } from '~/utils/slugify';

const { t } = useI18n();

const searchStore = useSearchStore();
const { q, filter } = storeToRefs(searchStore);

const props = defineProps({
    pageObj: Object,
});

const page = toRef(props, 'pageObj');

function displayQ() {
    return page?.value?.search?.q + ' ' + q.value;
}

useHrefLang(page?.value?.meta?.hreflang);

const anchorTitle = computed(() => useAnchorTitle(page?.value?.title));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.search-input {
    width: 66%;

    @include media-breakpoint-down(560) {
        width: 100%;
    }
}
</style>
